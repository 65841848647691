import React from 'react'

const TidakAdaEventAktif = () => {
  return (
    <>
      <div id="tidakAdaKegiatanForm" class="pages mb-3 mt-3">
        <h1 class="textCenter">TIDAK ADA KEGIATAN YANG AKTIF</h1>
        <h3 class="textCenter">الحمدلله جزا كم الله خيرا</h3>
      </div>
    </>
  )
}

export default TidakAdaEventAktif